/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Show more text on the banner of the homepage
        $('.banner-more').click( function() {
          event.preventDefault();
          $('h1.banner-heading').toggle();
          $('.banner-copy-wrapper').toggle();
          $('.banner-more').toggle();
        });

        $('.banner-close').click( function() {
          event.preventDefault();
          $('h1.banner-heading').toggle();
          $('.banner-copy-wrapper').toggle();
          $('.banner-more').toggle();
        });

        //Open and Close widgets

        $('.widgetizedArea>a').click( function(event) {
          event.preventDefault(event);
          $(this).parent().toggleClass('open');
        });

          //Open and CLose Category Listing Menu
        $('#category-listing-heading').click( function(event) {
            event.preventDefault(event);
            $('.category-listing-ul').toggleClass('category-listing-ul--open');
        });

        // toggle category descrition
        $('.category-overview__link--read-more').on("click", function (e) {
          e.preventDefault();
          $('.category-overview__link--read-more').addClass('category-overview__link--read-more-disable');
          $('.category-overview__desc-container').addClass('category-overview__desc-container--active');
        });

        $('.category-overview__link--close').on("click", function (e) {
          e.preventDefault();
          $('.category-overview__link--read-more').removeClass('category-overview__link--read-more-disable');
          $('.category-overview__desc-container').removeClass('category-overview__desc-container--active');
        });

          //Timeline scroll functions

          // Link1

          $(document).ready(function () {
              $('#timeline-link-one').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 180
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
          });

          // Link2

          $(document).ready(function () {
              $('#timeline-link-one').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
              $('#timeline-link-two').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
              $('#timeline-link-three').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
              $('#timeline-link-four').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
              $('#timeline-link-five').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
              $('#timeline-link-six').on('click', function () {
                  // e.preventDefault();

                  var target = this.hash,
                      $target = $(target);

                  $('html, body').stop().animate({
                      'scrollTop': $target.offset().top - 100
                  }, 900, 'swing', function () {
                  });


                  return false;
              });
          });


        //Setting Google Maps for the Our Stores Page
          if($('.our-stores').length > 0) {
              var isDraggable = $(document).width() > 768 ? true : false;
              $(window).load(function () {
                  new Maplace({
                      map_div: '#gmap-one',
                      locations: [{
                          lat: 51.509412,
                          lon: -0.136048,
                          zoom: 16,
                          icon: 'http://s1.wp.cordings.co.uk/news/wp-content/uploads/2017/03/google-maps-marker.png'
                      }],
                      map_options: {
                          scrollwheel: false,
                          data: false,
                          disableDefaultUI: true,
                          draggable: isDraggable
                      },
                      styles: {
                          'LabelOff': [{
                              "featureType": "poi",
                              "elementType": "labels",
                              "stylers": [
                                  {"visibility": "off"}
                              ]
                          }]
                      }
                  }).Load();
              });

              $(window).load(function () {

                  new Maplace({
                      map_div: '#gmap-two',
                      generate_controls: false,
                      locations: [{
                          lat: 53.993957,
                          lon: -1.543195,
                          zoom: 16,
                          icon: 'http://s1.wp.cordings.co.uk/news/wp-content/uploads/2017/03/google-maps-marker.png'
                      }],
                      map_options: {
                          scrollwheel: false,
                          disableDefaultUI: true,
                          draggable: isDraggable
                      },
                      styles: {
                          'LabelOff': [{
                              "featureType": "poi",
                              "elementType": "labels",
                              "stylers": [
                                  {"visibility": "off"}
                              ]
                          }]
                      }
                  }).Load();
              });

          }

          //BXSlider for the Timeline Page

          $(document).ready(function() {
              if ($('.slider-wrapper').width() < 768) {
                  var ms = 2;
                  var sw = Math.floor($('.slider-wrapper').width() / ms);
              } else if ($('.slider-wrapper').width() < 1280) {
                  var ms = 4;
                  var sw = Math.floor($('.slider-wrapper').width() / ms);
              } else if ($('.slider-wrapper').width() > 1280) {
                  var ms = 6;
                  var sw = Math.floor($('.slider-wrapper').width() / ms);
              };
              $('.bxslider').bxSlider({
                  pager: false,
                  slideWidth: sw,
                  maxSlides: ms,
                  slideMargin: 5,
                  infiniteLoop: false
              });
          });

          //BXSlider for the Style Genius Page Tab

          $(document).ready(function() {
              if ($('.tab-slider-wrapper').width() < 768) {
                  var sm = 5;
                  var ms = 2;
                  var sw = Math.floor($('.tab-slider-wrapper').width() / ms - 2 * 5 );
              } else if ($('.tab-slider-wrapper').width() < 1280) {
                  var sm = 5;
                  var ms = 4;
                  var sw = Math.floor($('.tab-slider-wrapper').width() / ms - 2 * 5 );
              } else if ($('.tab-slider-wrapper').width() > 1280) {
                  var sm = 5;
                  var ms = 5;
                  var sw = Math.floor($('.tab-slider-wrapper').width() / ms - 2 * 5 );
              };
              $('.tab-slider').bxSlider({
                  pager: false,
                  slideWidth: sw,
                  maxSlides: ms,
                  slideMargin: sm,
                  infiniteLoop: false
              });
          });


          //BXSlider for the Style Genius Page Feature Section

          $(document).ready(function() {
              if ($('body').width() < 768) {
                  var sm = 5;
                  var ms = 1;
                  var sw = Math.floor($('#feature-section-slider').width() / ms - 2 * 5 );
              } else if ($('#feature-section-slider').width() < 1280) {
                  var sm = 5;
                  var ms = 2;
                  var sw = Math.floor($('#feature-section-slider').width() / ms - 2 * 5 );
              };

              if($('body').width() < 980) {
                  $('#feature-section-slider').bxSlider({
                      pager: false,
                      slideWidth: sw,
                      maxSlides: ms,
                      slideMargin: sm,
                      infiniteLoop: false
                  });
              }
          });


          //BXSlider for the Style Genius Page Preview Section

          $(document).ready(function() {
              if ($('body').width() < 768) {
                  var sm = 5;
                  var ms = 1;
                  var sw = Math.floor($('#preview-slider').width() / ms - 2 * 5 );
              } else if ($('#preview-slider').width() < 1280) {
                  var sm = 5;
                  var ms = 1;
                  var sw = 336;
              };

              if($('body').width() < 980) {
                  $('#preview-slider').bxSlider({
                      pager: false,
                      slideWidth: sw,
                      maxSlides: ms,
                      slideMargin: sm,
                      infiniteLoop: false
                  });
              }
          });

          //BXSlider for the Style Genius Page Second Preview Section

          $(document).ready(function() {
              if ($('body').width() < 768) {
                  var sm = 5;
                  var ms = 1;
                  var sw = Math.floor($('#preview-slider-second').width() / ms - 2 * 5 );
              } else if ($('#preview-slide-second').width() < 1280) {
                  var sm = 5;
                  var ms = 1;
                  var sw = 336;
              };

              if($('body').width() < 980) {
                  $('#preview-slider-second').bxSlider({
                      pager: false,
                      slideWidth: sw,
                      maxSlides: ms,
                      slideMargin: sm,
                      infiniteLoop: false
                  });
              }
          });




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };



  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.